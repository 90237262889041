import invariant from 'invariant'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './components/App'
import { MOUNTING_POINT_ID, VERSION } from './core/constants'
import './scss/main.scss'

global.APP_VERSION = VERSION

const mountingPoint = document.getElementById(MOUNTING_POINT_ID)

invariant(
  mountingPoint,
  'The mounting point of the application was not found.' +
    ` Please make sure an element with \`id="${MOUNTING_POINT_ID}"\` exist in index.html.`,
)

// Add here the components that need a specific configuration for test (See renderTest.js).
// All others can go in components/App/App.js.
ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  mountingPoint,
)
