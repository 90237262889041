import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { NamespacesConsumer } from 'react-i18next'

class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.node,
  }

  state = {
    error: null,
  }

  static getDerivedStateFromError(error) {
    return { error }
  }

  render() {
    const { children } = this.props
    const { error } = this.state

    if (error) {
      return (
        <NamespacesConsumer>
          {t => (
            <main>
              <p>{t('global.errorMessage')}</p>
              <p>{error.toString()}</p>
            </main>
          )}
        </NamespacesConsumer>
      )
    }

    return children
  }
}

export default ErrorBoundary
