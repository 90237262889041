import React, { Suspense } from 'react'
import { Route, Switch } from 'react-router'
import ErrorBoundary from '../ErrorBoundary'
import classes from './App.module.scss'

const Home = React.lazy(() => import('../Home'))

function App() {
  return (
    <ErrorBoundary>
      <div className={classes.app}>
        <Suspense fallback={<div />}>
          <Switch>
            <Route path="/" component={Home} />
          </Switch>
        </Suspense>
      </div>
    </ErrorBoundary>
  )
}

export default App
